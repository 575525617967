class LearningSystem {
    private selectedClass = "selected";
    private hiddenClass = "hidden";
    private noScrollClass = "no-scroll";
    private breakpoint = 920;

    private items: NodeListOf<HTMLElement>;
    private contentContainer: HTMLElement;
    private content: NodeListOf<HTMLElement>;

    private currentIndex = null;

    constructor(private block: HTMLElement) {
        this.items = this.block.querySelectorAll(".tree .item");
        this.contentContainer = this.block.querySelector(".content-container");
        this.content = this.contentContainer.querySelectorAll(".content");
        this.registerEventHandlers();
    }

    private registerEventHandlers() {
        this.items.forEach((i) => {
            i.addEventListener("click", () => this.select(parseInt(i.dataset.index)));
            i.addEventListener("keypress", (e) => {
                if (e.code === "Enter" || e.code === "Space" || e.code === "NumpadEnter") {
                    e.preventDefault();
                    this.select(parseInt(i.dataset.index));
                }
            });
        });

        this.content.forEach((c) => {
            c.querySelector(".close")?.addEventListener("click", () => this.close());
        });

        // close when clicking backdrop
        this.contentContainer.addEventListener("click", (e) => {
            if (e.target === this.contentContainer) {
                this.close();
            }
        });

        // close with escape
        window.addEventListener("keydown", (e) => {
            if (this.currentIndex !== null && e.code === "Escape") {
                this.close();
            }
        });
    }

    public select(index: number) {
        if (this.currentIndex !== null) {
            this.toggle(this.currentIndex, false);
        }
        this.toggleContentContainer(true);
        this.toggle(index, true);
        this.currentIndex = index;
        this.toggleNoScroll(true);
    }

    public close() {
        this.toggleContentContainer(false);
        if (this.currentIndex !== null) {
            this.toggle(this.currentIndex, false);
            this.currentIndex = null;
        }
        this.toggleNoScroll(false);
    }

    private toggle(index: number, on: boolean) {
        this.items[index].classList.toggle(this.selectedClass, on);
        this.content[index].classList.toggle(this.hiddenClass, !on);
    }

    private toggleContentContainer(on: boolean) {
        this.contentContainer.classList.toggle(this.hiddenClass, !on);
    }

    private toggleNoScroll(on: boolean) {
        document.body.classList.toggle(this.noScrollClass, on);
    }
}

export default LearningSystem;
