export class InfoWithImages {
    private container: HTMLElement;
    private headings: NodeListOf<HTMLElement>;
    private infos: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        this.container = container;
        this.headings = this.container.querySelectorAll(".block-heading");
        this.infos = this.container.querySelectorAll(".info-block");

        this.headings.forEach((heading) => {
            heading.addEventListener("click", (e) => {
                const target = e.target as HTMLElement;

                if (target.classList.contains("active")) {
                    return;
                } else {
                    let infoName = "";
                    target.classList.forEach((className) => {
                        if (className !== "block-heading" && className !== "active" && className !== "h6") {
                            infoName = className;
                            return;
                        }
                    });
                    this.setActiveState(infoName);
                }
            });
        });
    }

    private setActiveState(className: string) {
        this.toggleActiveStage(this.headings, className);
        this.toggleActiveStage(this.infos, className);
    }

    private toggleActiveStage(elements, className) {
        elements.forEach((element) => {
            if (element.classList.contains(className)) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    }
}
