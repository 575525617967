export class AccordionController {
    constructor() {
        const accordions = document.querySelectorAll(".accordion") as NodeListOf<HTMLElement>;
        accordions.forEach((accordion) => {
            // Get the initial height of the accordion, then collapse it
            const accordionBody = accordion.querySelector(".accordion-body") as HTMLElement;
            const accordionHead = accordion.querySelector(".accordion-head") as HTMLElement;
            if (accordionBody) {
                if (!accordion.classList.contains("open")) {
                    accordionBody.style.maxHeight = "0";
                } else {
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
                }

                accordionHead.addEventListener("click", (e) => {
                    const open = accordion.classList.toggle("open");
                    if (open) {
                        accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
                    } else {
                        accordionBody.style.maxHeight = "0";
                    }

                    this.updateParentAccordions(accordion);
                });
            }
        });
    }

    private updateParentAccordions(target: HTMLElement) {
        const targetBody = target.querySelector(".accordion-body") as HTMLElement;
        const parent = this.getParentWithMatchingSelector(target, ".accordion");
        if (parent !== null) {
            const parentBody = parent.querySelector(".accordion-body") as HTMLElement;
            const open = target.classList.contains("open");
            const height = parseInt(targetBody.style.maxHeight);
            if (open) {
                parentBody.style.maxHeight = parentBody.scrollHeight + height + "px";
            } else {
                parentBody.style.maxHeight = parentBody.scrollHeight - height + "px";
            }
            this.updateParentAccordions(parent);
        }
    }

    private getParentWithMatchingSelector(target: HTMLElement, selector: string): HTMLElement | null {
        const items = document.querySelectorAll(selector) as NodeListOf<HTMLElement>;
        let parent = null;
        for (let i = 0; i < items.length && parent == null; ++i) {
            const currentItem = items[i];
            if (currentItem !== target && currentItem.contains(target)) {
                parent = currentItem;
            }
        }

        return parent;
    }
}
