export class Search {
    private searchButtons: NodeListOf<HTMLElement>;
    private searchBoxes: NodeListOf<HTMLElement>;

    constructor() {
        this.searchButtons = <NodeListOf<HTMLElement>>document.querySelectorAll(".search-button");
        this.searchBoxes = <NodeListOf<HTMLElement>>document.querySelectorAll(".search-box");

        this.searchButtons.forEach((button) => {
            button.addEventListener("click", () => {
                let active = false;
                this.searchBoxes.forEach((searchBox) => {
                    active = searchBox.classList.toggle("active");
                });
                button.classList.toggle("active", active);
            });
        });
    }
}
