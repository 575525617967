export class HeaderMenu {
    private hoverElements: NodeListOf<HTMLElement>;
    private navPages: NodeListOf<HTMLElement>;
    private readonly burgerMenu: HTMLElement;
    private pageNavigation: HTMLElement;

    constructor() {
        const pageHeader = document.querySelector("#PageHeader");
        if (pageHeader) {
            this.hoverElements = pageHeader.querySelectorAll(".hover-element");
            this.pageNavigation = pageHeader.querySelector(".page-navigation");
            this.burgerMenu = pageHeader.querySelector("#BurgerMenu");

            if (this.burgerMenu) {
                this.burgerMenu.addEventListener("click", () => {
                    this.toggleHoverMenu();
                });
            }
        }

        const headerContent = document.querySelector(".header-content");

        if (headerContent) {
            window.addEventListener("scroll", () => {
                headerContent.classList.toggle("collapse", window.scrollY >= 110);
            });
        }
    }

    private toggleHoverMenu = (visible?: boolean) => {
        this.hoverElements.forEach((element) => {
            element.classList.toggle("hidden", visible == undefined ? undefined : !visible);
        });

        this.pageNavigation.classList.toggle("overlay", visible == undefined ? undefined : !visible);
        this.burgerMenu.classList.toggle("open", visible == undefined ? undefined : !visible);
    };
}
