export class TabSelect {
    private container: HTMLElement;
    private selectButtons: NodeListOf<HTMLButtonElement>;
    private tabTexts: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        this.container = container;
        this.selectButtons = this.container.querySelectorAll("button");
        this.tabTexts = this.container.querySelectorAll(".tab-text");

        this.setupSelectButtons();
    }

    private setupSelectButtons() {
        this.selectButtons.forEach((button) => {
            button.addEventListener("click", (event) => {
                this.removeSelectedButton();
                this.removeSelectedText();
                button.classList.toggle("selected", true);
                this.addSelectedText(button);
            });
        });
    }

    private removeSelectedButton() {
        this.selectButtons.forEach((button) => {
            button.classList.toggle("selected", false);
        });
    }

    private removeSelectedText() {
        this.tabTexts.forEach((text) => {
            text.classList.toggle("selected", false);
        });
    }

    private addSelectedText(button: HTMLButtonElement) {
        button.classList.forEach((cls) => {
            if (cls.startsWith("tab")) {
                this.tabTexts.forEach((text) => {
                    if (text.classList.contains(cls)) {
                        text.classList.toggle("selected");
                        return;
                    }
                });
            }
        });
    }
}
