type Person = {
    name: string;
    position: string;
    description: string;
    origin: string;
    specialization: string;
    portrait: HTMLImageElement;
};

export class TeamOverlay {
    private container: HTMLElement;
    private people: NodeListOf<HTMLElement>;
    private overlay: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;

        this.people = this.container.querySelectorAll(".person");
        this.overlay = this.container.querySelector(".overlay");

        this.people.forEach((person) => {
            person.addEventListener("click", () => {
                const personData: Person = {
                    name: person.dataset.name,
                    position: person.dataset.position,
                    description: person.dataset.description,
                    origin: person.dataset.origin,
                    specialization: person.dataset.specialization,
                    portrait: person.querySelector<HTMLImageElement>(".portrait"),
                };

                this.openOverlay(personData);
            });
        });

        this.overlay.addEventListener("click", () => {
            this.closeOverlay();
        });

        window.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.closeOverlay();
            }
        });
    }

    private openOverlay(personData: Person) {
        const portrait: HTMLImageElement = this.overlay.querySelector<HTMLImageElement>(".portrait-round");
        portrait.src = personData.portrait.src;
        portrait.alt = personData.portrait.alt;
        portrait.width = personData.portrait.width;
        portrait.height = personData.portrait.height;
        const portraitMobile: HTMLImageElement = this.overlay.querySelector<HTMLImageElement>(".portrait-round-mobile");
        portraitMobile.src = personData.portrait.src;
        portraitMobile.alt = personData.portrait.alt;
        portraitMobile.width = personData.portrait.width;
        portraitMobile.height = personData.portrait.height;

        this.overlay.querySelector(".fullname").innerHTML = personData.name;
        this.overlay.querySelector(".fullname-mobile").innerHTML = personData.name;
        this.overlay.querySelector(".position").innerHTML = personData.position;
        this.overlay.querySelector(".position-mobile").innerHTML = personData.position;
        this.overlay.querySelector(".description").innerHTML = personData.description;
        this.overlay.querySelector(".origin").innerHTML = personData.origin;
        if (personData.specialization !== "") {
            this.overlay.querySelector(".specialization").innerHTML = personData.specialization;
        } else {
            this.overlay.querySelector(".specialization").classList.add("hidden");
            this.overlay.querySelector(".specialization-title").classList.add("hidden");
        }

        this.overlay.classList.remove("hidden");
    }

    private closeOverlay() {
        this.overlay.classList.add("hidden");
    }
}
