import Swiper, { Navigation, Pagination, Autoplay, EffectFade, EffectCreative } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, EffectCreative]);

const carouselSwiper = new Swiper(".carousel-swiper", {
    direction: "horizontal",
    loop: true,
    pagination: {
        clickable: true,
        el: ".swiper-pagination",
    },
});

const iconListSwiper = new Swiper(".icon-list-swiper", {
    direction: "horizontal",
    loop: false,
    pagination: {
        clickable: true,
        el: ".swiper-pagination",
    },
    slidesPerView: 1,
});

const newsSwiper = new Swiper(".news-swiper", {
    direction: "horizontal",
    loop: true,
    navigation: {
        nextEl: ".swiper-button-news-next",
        prevEl: ".swiper-button-news-prev",
    },
    speed: 200,
    fadeEffect: { crossFade: true },
    slidesPerView: 1,
});

const companySwiper = new Swiper(".companies-swiper", {
    direction: "horizontal",
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 40,
    centeredSlides: true,
    pagination: {
        clickable: true,
        el: ".swiper-pagination",
    },
    effect: "creative",
    creativeEffect: {
        prev: {
            translate: ["-100%", 0, 0],
            scale: 0.8,
            opacity: 0.4,
        },
        next: {
            translate: ["100%", 0, 0],
            scale: 0.8,
            opacity: 0.4,
        },
        limitProgress: 2,
    },
});

const newsTableSwiper = new Swiper(".news-table-section.swiper", {
    direction: "horizontal",
    loop: false,
    slidesPerView: "auto",
    spaceBetween: 80,
    breakpoints: {
        320: {
            spaceBetween: 20,
        },
        480: {
            spaceBetween: 30,
        },
        640: {
            spaceBetween: 40,
        },
        1000: {
            spaceBetween: 80,
        },
    },
    pagination: {
        clickable: true,
        el: ".swiper-pagination",
    },
});
