type CustomDate = {
    month: number;
    day: number;
};

export default class Calendar {
    private container: HTMLElement;
    private modules: NodeListOf<HTMLElement>;
    private entries: NodeListOf<HTMLElement>;
    private overlays: NodeListOf<HTMLElement>;
    private days: NodeListOf<HTMLElement>;
    private selectedDay: HTMLElement;
    private selectedDate: CustomDate;
    private prevDayButton: HTMLButtonElement;
    private nextDayButton: HTMLButtonElement;
    private filter: string[] = [];

    private moduleToClass = {
        calendar1: ["1", "2", "3"],
        calendar2: ["3", "4", "5", "6"],
        calendar3: ["7", "8", "9", "10"],
        calendar4: ["11", "12", "13", "14"],
        calendar5: ["15", "16", "17", "18"],
        calendar6: ["b2"],
        calendar7: ["c1"],
        calendar8: ["c2"],
    };

    constructor(container: HTMLElement) {
        this.container = container;

        this.modules = this.container.querySelectorAll(".module");
        this.entries = this.container.querySelectorAll(".entry");
        this.overlays = this.container.querySelectorAll(".entry-overlay");
        this.days = this.container.querySelectorAll(".calendar-view .day");
        this.selectedDay = this.container.querySelector(".calendar-selection .day");
        this.prevDayButton = this.container.querySelector(".calendar-selection .prev-day");
        this.nextDayButton = this.container.querySelector(".calendar-selection .next-day");

        this.initModules();
        this.initOverlays(this.overlays);
        this.initEntries(this.entries);
        this.initDays();
        this.setSelectedDate();
        this.initNavButton(this.prevDayButton);
        this.initNavButton(this.nextDayButton);
    }

    private initNavButton(button: HTMLButtonElement) {
        button.addEventListener("click", () => {
            const newSelectedDate = [].slice.apply(button.classList).find((element) => element.startsWith("date"));
            const prevSelectedDate = [].slice
                .apply(this.selectedDay.classList)
                .find((element) => element.startsWith("date"));
            this.selectedDay.classList.remove(prevSelectedDate);
            this.selectedDay.classList.add(newSelectedDate);
            this.setSelectedDate();
            this.updateDaySelection(this.container.querySelector(`.${newSelectedDate}`));
        });
    }

    private setNavButtons() {
        let year = new Date().getFullYear();
        let month = this.selectedDate.month;
        let day = this.selectedDate.day;

        const prevButtonDate = this.getPrevButtonDate(year, month, day);
        const nextButtonDate = this.getNextButtonDate(year, month, day);

        const currentPrevDate = this.getCurrentDate(this.prevDayButton);
        const currentNextDate = this.getCurrentDate(this.nextDayButton);

        this.setButtonClasses(this.prevDayButton, currentPrevDate, prevButtonDate);
        this.setButtonClasses(this.nextDayButton, currentNextDate, nextButtonDate);
    }

    private setButtonClasses(button: HTMLButtonElement, currentDate: string, nextDate: CustomDate) {
        button.classList.remove(currentDate);
        const newDate = `date-${this.pad(nextDate.month, 2)}-${this.pad(nextDate.day, 2)}`;
        button.classList.add(newDate);
        if (!this.container.querySelector(`.calendar-view .${newDate}`)) {
            button.classList.add("disabled");
            button.disabled = true;
        } else {
            button.classList.remove("disabled");
            button.disabled = false;
        }
    }

    private getCurrentDate(button: HTMLButtonElement) {
        return [].slice.apply(button.classList).find((element) => element.startsWith("date"));
    }

    private getPrevButtonDate(year, month, day): CustomDate {
        if (this.selectedDate.day - 1 < 1) {
            if (this.selectedDate.month - 1 < 1) {
                month = 12;
                year -= 1;
            } else {
                month = this.selectedDate.month - 1;
            }
            day = new Date(year, month, 0).getDate();
        } else {
            day -= 1;
        }

        return { month: month, day: day };
    }

    private getNextButtonDate(year, month, day): CustomDate {
        const date = new Date(year, month, 0);
        if (this.selectedDate.day + 1 > date.getDate()) {
            if (this.selectedDate.month + 1 > 12) {
                month = 1;
            } else {
                month = this.selectedDate.month + 1;
            }
            day = 1;
        } else {
            day += 1;
        }

        return { month: month, day: day };
    }

    private setSelectedDate() {
        const dateString = [].slice.apply(this.selectedDay.classList).find((element) => element.startsWith("date"));
        this.selectedDate = { month: Number(dateString.split("-")[1]), day: Number(dateString.split("-")[2]) };
        this.setNavButtons();
    }

    private initDays() {
        this.days.forEach((day) => {
            day.addEventListener("click", () => {
                if (!day.classList.contains("selected")) {
                    this.updateDaySelection(day);
                }
            });
        });
    }

    private updateDaySelection(day: HTMLElement) {
        this.clearSelection();
        day.classList.add("selected");
        this.setSelectedDay(day);
        const entries = this.selectedDay.querySelectorAll<HTMLElement>(".entry");
        const overlays = this.selectedDay.querySelectorAll<HTMLElement>(".entry-overlay");
        this.initEntries(entries);
        this.initOverlays(overlays);
    }

    private setSelectedDay(day: HTMLElement) {
        this.selectedDay.innerHTML = day.innerHTML;
    }

    private clearSelection() {
        this.days.forEach((day) => {
            day.classList.remove("selected");
        });
    }

    private initEntries(entries: NodeListOf<HTMLElement>) {
        entries.forEach((entry) => {
            entry.addEventListener("click", () => {
                const entryId = entry.dataset.entryId;
                const overlay = entry.parentElement.querySelector(`.${entryId}`);
                overlay.classList.add("visible");
            });
        });
    }

    private initOverlays(overlays: NodeListOf<HTMLElement>) {
        overlays.forEach((overlay) => {
            const content = overlay.querySelector(".content");
            content.addEventListener("click", (e) => {
                e.stopPropagation();
            });

            const closeButton = overlay.querySelector(".close");

            closeButton.addEventListener("click", () => {
                overlay.classList.remove("visible");
            });

            document.addEventListener("keydown", (event: KeyboardEvent) => {
                if (overlay.classList.contains("visible") && event.key === "Escape") {
                    overlay.classList.remove("visible");
                }
            });

            overlay.addEventListener("click", () => {
                overlay.classList.remove("visible");
            });
        });
    }

    private initModules() {
        this.modules.forEach((module) => {
            module.addEventListener("click", () => {
                if (module.classList.contains("selected")) {
                    module.classList.remove("selected");
                    const filterClass = [].slice
                        .apply(module.classList)
                        .find((element) => element.startsWith("calendar"));
                    this.removeFilter(filterClass);
                } else {
                    module.classList.add("selected");
                    const filterClass = [].slice
                        .apply(module.classList)
                        .find((element) => element.startsWith("calendar"));
                    this.addFilter(filterClass);
                }

                this.filterEntries();
            });
        });
    }

    private removeFilter(filter: string) {
        this.moduleToClass[filter].forEach((c) => {
            this.filter.splice(this.filter.indexOf(c), 1);
        });
    }

    private addFilter(filter: string) {
        this.filter = [...this.filter, ...this.moduleToClass[filter]];
    }

    private filterEntries() {
        this.entries.forEach((entry) => {
            const levels = entry.dataset.levels.split(",");
            if (this.filter.length === 0) {
                entry.classList.remove("filtered");
            } else {
                let filtered: boolean = true;
                this.filter.forEach((c) => {
                    if (levels.includes(c)) {
                        filtered = false;
                    }
                });
                if (filtered && !entry.classList.contains("filtered")) {
                    entry.classList.add("filtered");
                } else if (!filtered) {
                    entry.classList.remove("filtered");
                }
            }
        });
    }

    private pad(num, size) {
        const s = "0" + num;
        return s.slice(s.length - size);
    }
}
