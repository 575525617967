import { plausibleEvent } from "./PlausibleEvents";

const body = document.querySelector("body");
const detectScroll = () => {
    if (body.getBoundingClientRect().height / 4 < window.scrollY) {
        plausibleEvent("scroll");
        window.removeEventListener("scroll", detectScroll);
    }
};

if (body) {
    window.addEventListener("scroll", detectScroll);
}
