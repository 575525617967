import PlausibleEvents from "./PlausibleEvents";
import LearningSystem from "./LearningSystem";
import { HeaderMenu } from "./HeaderMenu";
import { TabSelect } from "./TabSelect";
import "./SwiperGalleries";
import { InfoTable } from "./InfoTable";
import { TeamOverlay } from "./TeamOverlay";
import { InfoWithImages } from "./InfoWithImages";
import { AccordionController } from "./AccordionController";
import { Search } from "./Search";
import Calendar from "./Calendar";
import "./ScrollWatcher";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// plausible events
const events = new PlausibleEvents();

// header menu
const headerMenu = new HeaderMenu();

// tab block element
const tabSections = document.querySelectorAll(".tab-section");
const tabSelectors = [];
tabSections.forEach((tabSection) => {
    tabSelectors.push(new TabSelect(tabSection as HTMLElement));
});

// Learning System
const learningSystems = document.querySelectorAll<HTMLElement>(".block.learning-system");
learningSystems.forEach((l) => new LearningSystem(l));

// Info List Content Element
const infoListSection = document.querySelectorAll<HTMLElement>(".info-list-section");
infoListSection.forEach((l) => new InfoTable(l));

// Team List Overlay
const teamSection = document.querySelectorAll<HTMLElement>(".team-section");
teamSection.forEach((t) => new TeamOverlay(t));

// Info With Images Section
const infoWithImagesSections = document.querySelectorAll<HTMLElement>(".info-with-images-section");
infoWithImagesSections.forEach((i) => new InfoWithImages(i));

// Info With Links and Buttons
const infoWithLinksAndButtonsSections = document.querySelectorAll<HTMLElement>(".info-with-links-and-buttons-section");
infoWithLinksAndButtonsSections.forEach((i) => new InfoWithImages(i));

// Accordion Controller
const accordionController = new AccordionController();

// Search
const search = new Search();

// Calendar
const calendarSections = document.querySelectorAll<HTMLElement>(".calendar-section");
calendarSections.forEach((calendarSection) => {
    new Calendar(calendarSection);
});
