export class InfoTable {
    private container: HTMLElement;
    private titles: NodeListOf<HTMLElement>;
    private infos: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        this.container = container;

        this.titles = this.container.querySelectorAll(".infos-mobile-header .entry-title");
        this.infos = this.container.querySelectorAll(".info");

        this.titles.forEach((title) => {
            title.addEventListener("click", (e) => {
                const target = e.currentTarget as HTMLElement;
                if (target.classList.contains("selected")) {
                    return;
                } else {
                    this.deselectTitles();
                    target.classList.add("selected");
                }
                const classes = Array.from(target.classList);
                this.showCurrentInfo(classes.filter((className) => className.includes("index"))[0]);
            });
        });
    }

    private deselectTitles() {
        this.titles.forEach((title) => {
            title.classList.remove("selected");
        });
    }

    private showCurrentInfo(className: string) {
        this.infos.forEach((info) => {
            if (info.classList.contains(className)) {
                info.classList.add("selected");
            } else {
                info.classList.remove("selected");
            }
        });
    }
}
